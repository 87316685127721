<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.type')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <v-select
                    v-model='form.type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='types_list'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='details.length > 0'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='details'
                :name="$t('details')"
                rules='required'
              >
                <b-form-group
                  :label="$t('details')"
                >
                  <v-select
                    v-model='form.details'
                    :dir='$store.state.appConfig.layout.direction'
                    label='description'
                    multiple
                    :options='details'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('price')"
                vid='price'
                rules='required'
              >
                <b-form-group
                  :label="$t('price')"
                >
                  <b-form-input
                    v-model='form.price'
                    :placeholder="$t('price')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('user_id_symbol')"
                vid='user_id_symbol'
                rules='required'
              >
                <b-form-group
                  :label="$t('user_id_symbol')"
                >
                  <b-form-input
                    v-model='form.user_id_symbol'
                    :placeholder="$t('user_id_symbol')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='icon'
                :name="$t('labels.icon')"
                rules='required'
              >
                <ImagePreview
                  v-model='icon'
                  :label="$t('labels.icon')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='default_map_icon'
                :name="$t('default_map_icon')"
              >
                <ImagePreview
                  v-model='default_map_icon'
                  :label="$t('default_map_icon')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='selected_map_icon'
                :name="$t('selected_map_icon')"
              >
                <ImagePreview
                  v-model='selected_map_icon'
                  :label="$t('selected_map_icon')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='not_selected_map_icon'
                :name="$t('not_selected_map_icon')"
              >
                <ImagePreview
                  v-model='not_selected_map_icon'
                  :label="$t('not_selected_map_icon')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      icon: null,
      default_map_icon: null,
      selected_map_icon: null,
      not_selected_map_icon: null,

      details: [],

      form: {
        name: {
          en: null,
          ar: null,
        },
        type: null,
        sort: null,
        price: null,
        icon: null,
        user_id_symbol: null,
        default_map_icon: null,
        selected_map_icon: null,
        not_selected_map_icon: null,
        details: [],
      },
      isDataLoaded: false,
      types_list: [
        {
          name: this.$t('monthly'),
          id: 1,
        },
        {
          name: this.$t('yearly'),
          id: 2,
        },
      ],

    }
  },

  watch: {
    default_map_icon(val) {
      if (val) {
        this.form.default_map_icon = val
      } else {
        this.form.default_map_icon = 'delete'
      }
    },
    selected_map_icon(val) {
      if (val) {
        this.form.selected_map_icon = val
      } else {
        this.form.defauselected_map_iconlt_map_icon = 'delete'
      }
    },
    not_selected_map_icon(val) {
      if (val) {
        this.form.not_selected_map_icon = val
      } else {
        this.form.not_selected_map_icon = 'delete'
      }
    },
    icon(val) {
      if (val) {
        this.form.icon = val
      } else {
        this.form.icon = 'delete'
      }
    },
  },
  computed: {
    toRedirectResource() {
      return 'package'
    },
  },
  created() {

    this.axios.get(`${websiteMainUrl()}/model-list/package-details`)
      .then(res => {
        this.details = res.data.data
      })

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            status: data.status,
            type: data.type,
            sort: data.sort,
            price: data.price,
            details: data.details,
            user_id_symbol: data.user_id_symbol,
          }
          this.icon = data.icon_path
          this.default_map_icon = data.default_map_icon_path
          this.selected_map_icon = data.selected_map_icon_path
          this.not_selected_map_icon = data.not_selected_map_icon_path

          this.isDataLoaded = true
        })
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {

        if (!($form.icon instanceof File) && $form.icon != 'delete') {
          delete $form.icon
        }

        if (!($form.default_map_icon instanceof File) && $form.default_map_icon != 'delete') {
          delete $form.default_map_icon
        }

        if (!($form.selected_map_icon instanceof File) && $form.selected_map_icon != 'delete') {
          delete $form.selected_map_icon
        }

        if (!($form.not_selected_map_icon instanceof File) && $form.not_selected_map_icon != 'delete') {
          delete $form.not_selected_map_icon
        }

        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

  },
}
</script>
